const Footer = () => {
  return (
    <div className="row">
      <div className="col-1">
        <footer>
          <span>🎉</span>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
